import { RootState } from '@/redux';
import { Fluctuation } from '@/types/models/fluctuation';
import { FluctuationLog } from '@/types/models/fluctuation-log';
import { RatePlan } from '@/types/models/rate-plan';
import { RoomType } from '@/types/models/room-type';
import { apiV1RmsService } from '@/utils/api';
import { formatDateApi } from '@/utils/dates';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { stringify } from 'query-string';

type FluctuationDetailsData = {
  fluctuation: Fluctuation;
};

type FluctuationConfigOptions = {
  selectedDates: Date[];
  selectedRatePlans: RatePlan[];
  selectedRoomTypes: RoomType[];
};

type RmsSliceState = {
  fluctuations: Fluctuation[];
  fluctuationsLoading: boolean;
  fluctuationDetailsModalOpened: FluctuationDetailsData | null;
  fluctuationLogDetailsModalOpened: FluctuationLog | null;
  fluctuationConfigOptions: FluctuationConfigOptions;
  calendarRows: Date[][];
};

const initialState: RmsSliceState = {
  fluctuations: [],
  fluctuationsLoading: false,
  fluctuationDetailsModalOpened: null,
  fluctuationLogDetailsModalOpened: null,
  fluctuationConfigOptions: {
    selectedDates: [],
    selectedRatePlans: [],
    selectedRoomTypes: [],
  },
  calendarRows: [],
};

const rmsSlice = createSlice({
  name: 'rms',
  initialState,
  reducers: {
    closeFluctuationDetails(state) {
      state.fluctuationDetailsModalOpened = null;
    },
    setFluctuationLogDetailsOpened(state, action: PayloadAction<FluctuationLog | null>) {
      state.fluctuationLogDetailsModalOpened = action.payload;
    },
    setFluctuationConfigOptions(state, action: PayloadAction<FluctuationConfigOptions>) {
      state.fluctuationConfigOptions = action.payload;
    },
    setFluctuationsCalendarRows(state, action: PayloadAction<Date[][]>) {
      state.calendarRows = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRmsFlutuations.pending, (state) => {
        state.fluctuationsLoading = true;
        state.fluctuations = [];
      })
      .addCase(fetchRmsFlutuations.fulfilled, (state, action: PayloadAction<Fluctuation[]>) => {
        state.fluctuationsLoading = false;
        state.fluctuations = action.payload;
      })
      .addCase(fetchRmsFlutuations.rejected, (state, action) => {
        state.fluctuationsLoading = false;
        state.fluctuations = [];
      })
      .addCase(
        openRmsFlutuationDetails.fulfilled,
        (state, action: PayloadAction<FluctuationDetailsData>) => {
          state.fluctuationDetailsModalOpened = action.payload;
        },
      )
      .addCase(openRmsFlutuationDetails.rejected, (state) => {
        state.fluctuationDetailsModalOpened = null;
      });
  },
});

export const fetchRmsFlutuations = createAsyncThunk<Fluctuation[], void, { state: RootState }>(
  'rms/fetchFlutuations',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { commonFilters, rmsFilters } = getState().filters;
      const { startDate, endDate } = commonFilters;
      const { ratePlan, roomTypes, status, fluctuationRule } = rmsFilters;
      const params: any = {
        startDate: formatDateApi(startDate),
        endDate: formatDateApi(endDate),
      };

      if (!!status && status !== 'ALL') {
        params.status = status;
      }

      if (!ratePlan?._id) {
        return [];
      }
      params.ratePlan = ratePlan?._id;

      if (!roomTypes?.length) {
        return [];
      }

      params.roomTypes = roomTypes?.map((x) => x._id);

      if (!fluctuationRule?._id) {
        return [];
      }
      params.fluctuationRule = fluctuationRule?._id;

      const response = await apiV1RmsService.get<Fluctuation[]>(
        `/fluctuations?${stringify(params, { arrayFormat: 'bracket' })}`,
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const openRmsFlutuationDetails = createAsyncThunk<
  FluctuationDetailsData,
  string,
  { state: RootState }
>('rms/openRmsFlutuationDetails', async (fluctuationId, { rejectWithValue, getState }) => {
  try {
    const response = await apiV1RmsService.get<FluctuationDetailsData>(
      `/fluctuations/details/${fluctuationId}`,
    );

    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const {
  setFluctuationLogDetailsOpened,
  closeFluctuationDetails,
  setFluctuationConfigOptions,
  setFluctuationsCalendarRows,
} = rmsSlice.actions;
export default rmsSlice.reducer;
